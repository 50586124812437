export const UrlConstants = {
  Base_Url: "https://srninfotech.com/projects/Sky Commodity/backend/api",
};

export const SignUpUrl = UrlConstants.Base_Url + `/register`;
export const SignInUrl = UrlConstants.Base_Url + `/login`;
export const LogoutUrl = UrlConstants.Base_Url + `/logout`;
export const ForgotPasswordUrl = UrlConstants.Base_Url + `/forget-password`;
export const ResetPasswordUrl = UrlConstants.Base_Url + `/reset-password`;
export const AuthenticationUrl = UrlConstants.Base_Url + `/2fa/verify`;
// export const RefreshTokenUrl = UrlConstants.Base_Url+`/refresh-token`;
